import { HYDRATE } from "next-redux-wrapper";
import { AnyAction, combineReducers } from "redux";
import { checkoutReducer } from "./checkout/checkout.reducer";
import { cartReducer } from "./cart/cart.reducer";
import { filterReducer } from "./filter/filter.reducer";
import { miscReducer } from "./misc/misc.reducer";
import { productReducer } from "./product/product.reducer";
import { ReduxStore } from "./store";
import { translationReducer } from "./translation/translation.reducer";
import { loaderReducer } from "./loader/loader.reducer";

export const reducer = (state: ReduxStore | undefined, action: AnyAction) => {
	// use to hydrate state between server and client
	if (action.type === HYDRATE) {
		return {
			...state, // use previous state
			...action.payload // apply delta from hydration
		};
	} else {
		return rootReducer(state, action);
	}
};

const rootReducer = combineReducers<ReduxStore>({
	cart: cartReducer,
	misc: miscReducer,
	product: productReducer,
	filter: filterReducer,
	translations: translationReducer,
	checkout: checkoutReducer,
	loader: loaderReducer
});
