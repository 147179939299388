import { EFilter, TFilterActions } from "./filter.action";

export interface IFilterState {
	displayedFiltersAnchor: string[];
}

const initialFilterState: IFilterState = {
	displayedFiltersAnchor: []
};

export const filterReducer = (state = initialFilterState, action: TFilterActions) => {
	switch (action.type) {
		case EFilter.AddDisplayedFilterAnchor:
			return addDisplayedFilterAnchor(action.payload, state);
		case EFilter.RemoveDisplayedFilterAnchor:
			return removeDisplayedFilterAnchor(action.payload, state);
		default:
			return state;
	}
};

const addDisplayedFilterAnchor = (filter: string, state: IFilterState) => {
	return {
		...state,
		displayedFiltersAnchor: [...state.displayedFiltersAnchor, filter]
	};
};

const removeDisplayedFilterAnchor = (filter: string, state: IFilterState) => {
	const updatedFiltersAnchor = state.displayedFiltersAnchor.filter((stateFilter) => {
		return stateFilter !== filter;
	});
	return {
		...state,
		displayedFiltersAnchor: updatedFiltersAnchor
	};
};
