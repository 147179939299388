import { IGenericAction } from "@redux/types";
import { ICartProduct } from "@shared/cart/cart.types";
import { setCartItemsCountInCookies } from "@shared/cart/cookies.utils";

export interface ICartDispatch {
	type: ECart;
}

export enum ECart {
	AddToCart = "AddToCart",
	SetItemsCount = "SetItemsCount",
	Queue = "Queue",
	Ready = "Ready"
}

export type TCartActions =
	| IGenericAction<ECart.AddToCart, ICartProduct>
	| IGenericAction<ECart.SetItemsCount, number>
	| IGenericAction<ECart.Queue, string>
	| IGenericAction<ECart.Ready, string>;

export const addToCart = (product: ICartProduct) => {
	return {
		type: ECart.AddToCart,
		payload: product
	};
};

export const setItemsCount = (itemsCount: number) => {
	setCartItemsCountInCookies(itemsCount);
	return {
		type: ECart.SetItemsCount,
		payload: itemsCount
	};
};

export const setQueue = () => {
	return {
		type: ECart.Queue
	};
};

export const setReady = () => {
	return {
		type: ECart.Ready
	};
};
