import { CookieSerializeOptions } from "cookie";
import { NextApiRequest, NextApiResponse, NextPageContext } from "next";
import nookies from "nookies";

export type TCookieContextRequest =
	| Pick<NextPageContext, "req">
	| {
			req: NextApiRequest;
	  }
	| null
	| undefined;

export type TCookieContextResponse =
	| Pick<NextPageContext, "res">
	| {
			res: NextApiResponse;
	  }
	| null
	| undefined;

export enum ECookieName {
	DktConnectAccessToken = "user_dkt_connect_access_token",
	DktConnectRefreshToken = "user_dkt_connect_refresh_token",
	Reconnect = "user_dkt_connect_reconnect",
	FirstName = "user_given_name",
	UserRedirect = "user_redirect",
	PostalCodeUser = "user_postal_code",
	ProvinceUser = "user_province",
	CartId = "user_cart_id",
	CartItemsCount = "user_cart_items_count",
	AlgoliaUserToken = "_ALGOLIA",
	UserStoreSelected = "user_store_selected",
	UserStoreIdSelected = "user_store_id_selected",
	UserErrorToken = "user_error_token"
}

export const MAX_AGE_COOKIE = 30 * 24 * 60 * 60; //1 month
export const MAX_AGE_COOKIE_MEDIUM = 5 * 60; //5 minutes
export const MAX_AGE_COOKIE_SMALL = 1 * 60; //1 minute
export const MAX_AGE_COOKIE_EXTRA_LARGE = 20 * 365 * 24 * 60 * 60; //20 years

export const DEFAULT_SET_COOKIE_OPTIONS = { maxAge: MAX_AGE_COOKIE, path: "/" };
export const DEFAULT_DELETE_COOKIE_OPTIONS = { path: "/" };
export const PROFILE_SET_COOKIE_OPTIONS = { maxAge: MAX_AGE_COOKIE_EXTRA_LARGE, path: "/" };
export const SMALL_COOKIE_OPTIONS = { maxAge: MAX_AGE_COOKIE_SMALL, path: "/" };

export const getCookieOptions = ({ expires, maxAge }: CookieSerializeOptions) => ({
	expires,
	maxAge,
	path: "/",
	httpOnly: process.env.NODE_ENV !== "test",
	secure: true
});

export const getCookie = (key: string, appCtx?: TCookieContextRequest) => {
	return nookies.get(appCtx)?.[key];
};

export const setCookie = (
	key: string,
	value: string,
	appCtx?: TCookieContextResponse,
	options: CookieSerializeOptions = DEFAULT_SET_COOKIE_OPTIONS
) => {
	return nookies.set(appCtx, key, value, options);
};

export const isCookieExisting = (key: string, appCtx?: TCookieContextRequest) => {
	const cookie = getCookie(key, appCtx);
	return !!cookie && cookie !== "undefined";
};

export const deleteCookie = (
	key: string,
	appCtx?: TCookieContextResponse,
	options: CookieSerializeOptions = DEFAULT_DELETE_COOKIE_OPTIONS
) => {
	return nookies.destroy(appCtx, key, options);
};

export const deleteCookiesUser = (appCtx?: TCookieContextResponse) => {
	const cookieNameArray = Object.values(ECookieName);
	cookieNameArray.forEach((cookieName) => {
		if (cookieName.startsWith("user_")) {
			return deleteCookie(cookieName, appCtx);
		}
	});
};
