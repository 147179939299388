import { ICartProduct } from "@shared/cart/cart.types";
import { ECart, TCartActions } from "./cart.actions";

export interface ICartState {
	latestAddedProduct: ICartProduct | undefined;
	itemsCount: number;
	loaderQueue: number;
	loaderReady: number;
	isLoading: boolean;
}

const initialCartState: ICartState = {
	latestAddedProduct: undefined,
	itemsCount: 0,
	loaderQueue: 0,
	loaderReady: 0,
	isLoading: false
};

export const cartReducer = (state = initialCartState, action: TCartActions) => {
	switch (action.type) {
		case ECart.AddToCart:
			return addProductToCart(action.payload, state);
		case ECart.SetItemsCount:
			return setItemsCount(action.payload, state);
		case ECart.Queue:
			return addToQueue(state);
		case ECart.Ready:
			return toggleReady(state);
		default:
			return state;
	}
};

const addProductToCart = (product: ICartProduct, state: ICartState) => {
	return {
		...state,
		latestAddedProduct: product
	};
};

const setItemsCount = (itemsCount: number, state: ICartState) => {
	return {
		...state,
		itemsCount
	};
};

const addToQueue = (state: ICartState) => {
	return {
		...state,
		loaderQueue: state.loaderQueue + 1,
		loaderReady: state.loaderReady,
		isLoading: true
	};
};

const toggleReady = (state: ICartState) => {
	return {
		...state,
		loaderQueue: state.loaderQueue,
		loaderReady: state.loaderReady + 1,
		isLoading: state.loaderQueue !== state.loaderReady + 1
	};
};
