const deviceSizes = [16, 32, 48, 64, 96, 128, 256, 320];
const imageSizes = [
	380, 472, 576, 640, 750, 828, 1080, 1200, 1440, 1680, 1800, 1920, 2048, 2520, 3000, 3250, 3620,
	3840
];

module.exports = {
	screens: {
		sm: { max: "600px" },
		md: { min: "601px" },
		lg: { min: "1024px" },
		xl: { min: "1440px" },
		xxl: { min: "1920px" }
	},
	widths: [...deviceSizes, ...imageSizes],
	deviceSizes,
	imageSizes
};
