import { IGenericAction } from "@redux/types";
import { IProductState } from "./product.reducer";

export enum EProduct {
	SetSize = "SetSize",
	ShowErrorSize = "ShowErrorSize"
}

export type TProductActions =
	| IGenericAction<EProduct.SetSize, Pick<IProductState, "articleId" | "size">>
	| IGenericAction<EProduct.ShowErrorSize, boolean>;

export const setSize = (props: Pick<IProductState, "size" | "articleId">) => {
	return {
		type: EProduct.SetSize,
		payload: props
	};
};

export const showErrorSize = () => {
	return {
		type: EProduct.ShowErrorSize,
		payload: true
	};
};
