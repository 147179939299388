import App, { AppContext, AppProps as NextAppProps } from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { SessionProvider } from "next-auth/react";
import { setReferer } from "@redux/misc/misc.actions";
import { ReduxStore, wrapper } from "@redux/store";
import { shouldRemoveHitLastViewed } from "@utils/app.utils";
import { ECookieName, getCookie } from "@utils/cookie.utils";
import "@shared/styles/tailwind.scss";
import "@shared/styles/lightgallery.css";
import "@components/Carousel/Carousel.scss";
import SignInError from "@components/SignInError/SignInError.component";
import { Session } from "next-auth";
import { LayoutProvider } from "@components/Layout/Layout.context";
import { useResponsive } from "@hooks/useDevice";
import { useIsHomepage } from "@hooks/useFollowHome";
import { buildGtmCode } from "@shared/datalayer/app-datalayer/app-datalayer";
import { Router } from "next/router";
import { datadogRum } from "@datadog/browser-rum";

const {
	publicRuntimeConfig: { datadog }
} = getConfig();

interface IPageProps {
	session: Session;
}

datadog.enabled &&
	datadogRum.init({
		applicationId: datadog.applicationId,
		clientToken: datadog.clientToken,
		site: datadog.site,
		service: datadog.service,
		env: datadog.env,
		version: datadog.version,
		sessionSampleRate: datadog.sessionSampleRate,
		sessionReplaySampleRate: datadog.sessionReplaySampleRate,
		trackUserInteractions: datadog.trackUserInteractions,
		trackResources: datadog.trackResources,
		trackLongTasks: datadog.trackLongTasks,
		defaultPrivacyLevel: datadog.defaultPrivacyLevel
	});

export interface IAppProps extends NextAppProps<IPageProps> {
	gtmId: string;
	refreshToken: string;
	hasRefreshToken: boolean;
	referer: string | undefined;
	pageProps: IPageProps;
}

export const Decathlon = ({
	Component,
	pageProps: { session, ...pageProps },
	gtmId,
	referer
}: IAppProps) => {
	const dispatch = useDispatch();
	const { isMobile, isTablet } = useResponsive();
	const isSmallScreen = isMobile || isTablet;
	const isHomepage = useIsHomepage();

	const [isRouteUpdated, setIsRouteUpdated] = useState(false);

	const {
		misc: { isScrollable }
	} = useSelector((state: ReduxStore) => state);

	useEffect(() => {
		if (referer) dispatch(setReferer(referer));
		shouldRemoveHitLastViewed();
	}, []);

	useEffect(() => {
		const handleRouteChange = () => {
			setIsRouteUpdated(true);
		};

		Router.events.on("routeChangeComplete", handleRouteChange);
		setIsRouteUpdated(true);
		return () => {
			Router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, []);

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
			</Head>
			<Helmet
				bodyAttributes={{
					class: `${isScrollable ? "scroll" : "noScroll"} ${
						isSmallScreen && isHomepage ? "noScrollIOS" : ""
					}`
				}}
			/>
			<>
				{gtmId && (
					<>
						<script
							defer={false}
							async={false}
							dangerouslySetInnerHTML={buildGtmCode(gtmId)}
							data-testid="App_google-tag-manager"
						/>
						{isRouteUpdated && (
							<script
								data-testid="App_google-tag-manager-reset"
								async={true}
								defer={true}
								dangerouslySetInnerHTML={{
									__html: `if(window.google_tag_manager){window.google_tag_manager['${gtmId}'].dataLayer.reset();} else {console.warn('Google tag manager could not be initialized. GTM_ID: ${gtmId}')}`
								}}
							/>
						)}
					</>
				)}
				<SessionProvider session={session}>
					<SignInError />
					<LayoutProvider>
						<Component {...pageProps} />
					</LayoutProvider>
				</SessionProvider>
			</>
		</>
	);
};

Decathlon.getInitialProps = async (appContext: AppContext) => {
	const { host, gtmId } = getConfig().serverRuntimeConfig;
	const {
		ctx: { req, res, locale }
	} = appContext;

	const region = getCookie(ECookieName.ProvinceUser, { req });
	const statusCode = res?.statusCode;
	const absoluteUrl = `${host}/${locale}`;

	const appProps = await App.getInitialProps(appContext);

	return {
		...appProps,
		pageProps: {
			region,
			statusCode,
			absoluteUrl
		},
		gtmId,
		referer: req?.headers?.referer
	};
};

export default wrapper.withRedux(Decathlon);
