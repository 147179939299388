import { IGenericAction } from "@redux/types";
import { ECheckout } from "./checkout.action";

export interface ICheckoutState {
	loaderQueue: number;
	loaderReady: number;
	isLoading: boolean;
	loaderCount: number;
}

const initialCheckoutState: ICheckoutState = {
	loaderQueue: 0,
	loaderReady: 0,
	isLoading: true,
	loaderCount: 0
};

export const checkoutReducer = (
	state: ICheckoutState = initialCheckoutState,
	action: IGenericAction<ECheckout, string>
) => {
	switch (action.type) {
		case ECheckout.Queue:
			return addToQueue(state);
		case ECheckout.Ready:
			return toggleReady(state);
		default:
			return state;
	}
};

const addToQueue = (state: ICheckoutState) => {
	return {
		...state,
		loaderQueue: state.loaderQueue + 1,
		loaderReady: state.loaderReady,
		isLoading: true
	};
};

const toggleReady = (state: ICheckoutState) => {
	return {
		loaderQueue: state.loaderQueue,
		loaderReady: state.loaderReady + 1,
		isLoading: state.loaderQueue !== state.loaderReady + 1,
		loaderCount: state.loaderCount + 1
	};
};
