import { EMisc, TMiscActions } from "./misc.actions";
export interface IMiscState {
	isScrollable: boolean;
	referer?: string;
}

const initialMiscState: IMiscState = {
	isScrollable: true
};

export const miscReducer = (state = initialMiscState, action: TMiscActions) => {
	switch (action.type) {
		case EMisc.ToggleScroll:
			return {
				...state,
				isScrollable: action.payload
			};
		case EMisc.SetReferer:
			return {
				...state,
				referer: action.payload
			};
		default:
			return state;
	}
};
