/* eslint-disable @typescript-eslint/no-explicit-any */
export enum ESessionStorageName {
	HitLastViewed = "hitLastViewed",
	AisInfiniteHits = "ais.infiniteHits"
}

export const getSessionStorageItem = (key: string, type = "string") => {
	if (typeof window === "undefined") return undefined;
	const value = window.sessionStorage.getItem(key);
	if (!value) return null;
	switch (type) {
		case "string":
			return value;
		case "number":
			return Number(value);
		case "boolean":
			return Boolean(value);
		default:
			return JSON.parse(value);
	}
};

export const setSessionStorageItem = (key: string, value: any) => {
	if (typeof window !== "undefined") {
		switch (typeof value) {
			case "string":
				window.sessionStorage.setItem(key, value);
				break;
			case "number":
			case "boolean":
				window.sessionStorage.setItem(key, value.toString());
				break;
			default:
				window.sessionStorage.setItem(key, JSON.stringify(value));
		}
	}
};

export const isSessionStorageItemExists = (key: string) => {
	if (typeof window !== "undefined") {
		return Boolean(window.sessionStorage.getItem(key));
	}
	return false;
};

export const removeSessionStorageItem = (key: string) => {
	if (typeof window !== "undefined") {
		window.sessionStorage.removeItem(key);
	}
};
