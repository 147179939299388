import { EProduct, TProductActions } from "./product.action";

export interface IProductState {
	size: string;
	articleId: number;
	isErrorSize: boolean;
}

const initialProductState: IProductState = {
	size: "",
	articleId: 0,
	isErrorSize: false
};

export const productReducer = (state = initialProductState, action: TProductActions) => {
	switch (action.type) {
		case EProduct.SetSize:
			return setProductSize(action.payload, state);
		case EProduct.ShowErrorSize:
			return setProductErrorSize(action.payload, state);
		default:
			return state;
	}
};

const setProductSize = (
	payload: Pick<IProductState, "articleId" | "size">,
	state: IProductState
) => {
	return {
		...state,
		...payload,
		isErrorSize: false
	};
};

const setProductErrorSize = (payload: boolean, state: IProductState) => ({
	...state,
	isErrorSize: payload
});
