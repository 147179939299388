export enum EDevice {
	Mobile = "sm",
	Tablet = "md",
	Desktop = "lg",
	BigScreen = "xl",
	ExtraLargeScreen = "xxl"
}

export enum EKeyboard {
	Backspace = "Backspace",
	Delete = "Delete",
	Escape = "Escape"
}
