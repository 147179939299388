import { createContext, ReactNode, useMemo, useState } from "react";
import { ILayoutContext } from "./type";

export const LayoutContext = createContext<ILayoutContext>({ searchBarFocused: false });

type TLayoutProvider = {
	children: ReactNode;
	value?: ILayoutContext;
};

export const LayoutProvider = ({ children, value }: TLayoutProvider) => {
	const [searchBarFocused, setSearchBarFocused] = useState(false);
	const contextValue: ILayoutContext = useMemo(() => {
		return {
			searchBarFocused,
			setSearchBarFocused
		};
	}, [searchBarFocused]);
	return <LayoutContext.Provider value={value || contextValue}>{children}</LayoutContext.Provider>;
};
