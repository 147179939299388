import {
	deleteCookie,
	ECookieName,
	getCookie,
	setCookie,
	TCookieContextRequest,
	TCookieContextResponse
} from "@utils/cookie.utils";

export const setCartItemsCountInCookies = (
	cartItemsCount: number,
	appCtx?: TCookieContextResponse
) => {
	setCookie(ECookieName.CartItemsCount, cartItemsCount?.toString(), appCtx);
};

export const getCartItemsCountInCookies = () => {
	return Number(getCookie(ECookieName.CartItemsCount));
};

export const isCartItemsCountInCookies = () => {
	return !isNaN(getCartItemsCountInCookies());
};

export const deleteCartItemsCountInCookies = (appCtx?: TCookieContextResponse) => {
	deleteCookie(ECookieName.CartItemsCount, appCtx);
};

export const setCartIdInCookies = (cartId: string, appCtx?: TCookieContextResponse) => {
	setCookie(ECookieName.CartId, cartId, appCtx);
};

export const getCartIdInCookies = (appCtx?: TCookieContextRequest) => {
	return getCookie(ECookieName.CartId, appCtx);
};

export const isCartIdInCookies = (appCtx?: TCookieContextRequest) => {
	const cookieCart = getCartIdInCookies(appCtx);
	return Boolean(cookieCart) && cookieCart !== "undefined" && cookieCart !== "null";
};

export const deleteCartIdInCookies = (appCtx?: TCookieContextResponse) => {
	deleteCookie(ECookieName.CartId, appCtx);
};

export const deleteCartInfoInCookies = (appCtx?: TCookieContextResponse) => {
	deleteCartIdInCookies(appCtx);
	deleteCartItemsCountInCookies(appCtx);
};
