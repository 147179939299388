import { IGenericAction } from "@redux/types";
import { ELoader, ILoaderState } from "./loader.types";

const initialLoaderState: ILoaderState = {
	loaderQueue: 0,
	loaderReady: 0,
	isLoading: true,
	loaderCount: 0
};

export const loaderReducer = (
	state: ILoaderState = initialLoaderState,
	action: IGenericAction<ELoader, string>
) => {
	switch (action.type) {
		case ELoader.Queue:
			return addToQueue(state);
		case ELoader.Ready:
			return toggleReady(state);
		default:
			return state;
	}
};

const addToQueue = (state: ILoaderState) => {
	return {
		...state,
		loaderQueue: state.loaderQueue + 1,
		isLoading: true
	};
};

const toggleReady = (state: ILoaderState) => {
	return {
		loaderQueue: state.loaderQueue,
		loaderReady: state.loaderReady + 1,
		isLoading: state.loaderQueue !== state.loaderReady + 1,
		loaderCount: state.loaderCount + 1
	};
};
