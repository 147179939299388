import { IGenericAction } from "@redux/types";
import { EDevice } from "@shared/types/misc.types";

export enum EMisc {
	ToggleScroll = "ToggleScroll",
	SetReferer = "SetReferer"
}

export type TMiscActions =
	| IGenericAction<EMisc.SetReferer, EDevice>
	| IGenericAction<EMisc.ToggleScroll, boolean>;

export const toggleScroll = (scroll: boolean) => {
	return {
		type: EMisc.ToggleScroll,
		payload: scroll
	};
};

export const setReferer = (referer: string) => {
	return {
		type: EMisc.SetReferer,
		payload: referer
	};
};
