export enum ELoader {
	Queue = "Queue",
	Ready = "Ready"
}

export interface ILoaderState {
	loaderQueue: number;
	loaderReady: number;
	isLoading: boolean;
	loaderCount: number;
}
