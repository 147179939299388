import { TContentTranslation } from "@shared/types/translations.types";
import { IGenericAction } from "@redux/types";

export enum ETranslationActions {
	SetTranslation = "SetTranslation"
}

export type TTranslationActions = IGenericAction<
	ETranslationActions.SetTranslation,
	TContentTranslation
>;

export const addTranslation = (translation: TContentTranslation) => {
	return {
		type: ETranslationActions.SetTranslation,
		payload: translation
	};
};
