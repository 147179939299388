import {
	ECookieName,
	TCookieContextRequest,
	TCookieContextResponse,
	deleteCookie,
	getCookie,
	setCookie
} from "@utils/cookie.utils";

export const setErrorToken = (res: TCookieContextResponse) => {
	setCookie(ECookieName.UserErrorToken, "true", res);
};

export const isErrorToken = (req?: TCookieContextRequest) => {
	return Boolean(getCookie(ECookieName.UserErrorToken, req));
};

export const deleteErrorToken = (res: TCookieContextResponse) => {
	deleteCookie(ECookieName.UserErrorToken, res);
};
