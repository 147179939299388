import { ETranslationActions, TTranslationActions } from "./translation.action";

const initialTranslationsState = {};

export const translationReducer = (
	state = initialTranslationsState,
	action: TTranslationActions
) => {
	if (action.type === ETranslationActions.SetTranslation) {
		return {
			...state,
			...action.payload
		};
	}
	return state;
};
