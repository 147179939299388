/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */

//TODO replace this deprecated createStore
import { createStore, applyMiddleware } from "redux";
import { createWrapper } from "next-redux-wrapper";
import thunkMiddleware from "redux-thunk";

import { reducer } from "./reducer";
import { ICartState } from "./cart/cart.reducer";
import { IMiscState } from "./misc/misc.reducer";
import { IProductState } from "./product/product.reducer";
import { IFilterState } from "./filter/filter.reducer";
import { TContentTranslation } from "@shared/types/translations.types";
import { ICheckoutState } from "./checkout/checkout.reducer";
import { ILoaderState } from "./loader/loader.types";

export type ReduxStore = {
	cart: ICartState;
	misc: IMiscState;
	product: IProductState;
	filter: IFilterState;
	translations: TContentTranslation<string>;
	checkout: ICheckoutState;
	loader: ILoaderState;
};

const bindMiddleware = (middleware: any[]) => {
	if (process.env.NODE_ENV !== "production") {
		const { composeWithDevTools } = require("redux-devtools-extension");
		return composeWithDevTools(applyMiddleware(...middleware));
	}
	return applyMiddleware(...middleware);
};

const initStore = () => createStore(reducer, bindMiddleware([thunkMiddleware]));

export const wrapper = createWrapper(initStore);
