export enum ECheckout {
	Queue = "Queue",
	Ready = "Ready"
}

export const setQueue = () => {
	return {
		type: ECheckout.Queue
	};
};

export const setReady = () => {
	return {
		type: ECheckout.Ready
	};
};
