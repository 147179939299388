export enum ERefreshError {
	RefreshAccessTokenError = "RefreshAccessTokenError"
}

export enum EState {
	Pending = "Pending",
	AtTheDoor = "AtTheDoor"
}

export interface IJWTAccessToken {
	exp: number;
}

export interface IJWTToken {
	exp: number;
}

export interface AuthFedIdProfile {
	sub: string;
	partyNumber: string;
	preferredLanguage: string;
	c: string;
	role: string[];
	mail: string;
	sitepartynumber: string;
	displayName: string;
	givenName: string;
	cn: string;
	sitetype: string;
	uid: string;
	site: string;
	federation_idp: string;
	sn: string;
	jobname: string;
	sitename: string;
	mobile: string;
	title: string;
	objectclass: string[];
	uuid: string;
	allsites: string;
	familyName: string;
	costcenter: string;
	photourl: string;
}
