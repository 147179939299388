import { RenderMark, RenderNode, RenderText, Options } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";
import { EBlockTypenames, EComponentTypenames } from "./contentful.types";
import { defaultLocale, defaultSiteLocale } from "@config/languages";
import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import { ITwoColumnsCflData } from "@components/ContentfulComponents/BlockTwoColumnsContent/types";
import { IAssetCflData, IMediaCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { IBannerCflData } from "@components/ContentfulComponents/ComponentBanner/type";
import { IJsonCflData } from "@components/ContentfulComponents/ComponentJson/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import {
	EContentfulPageType,
	EPageTypenames
} from "@components/ContentfulComponents/ComposePage/type";
import { IContentPagesContentBlockCflData } from "@components/ContentfulComponents/ContentPagesContentBlock/type";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";
import { TTranslatableContent } from "./translations.utils";
import { ISeparatorCflData } from "@components/ContentfulComponents/ComponentSeparator/type";
import { IAssetWithTextCflData } from "@components/ContentfulComponents/ComponentAssetWithText/type";
import { IHighlightedTextCflData } from "@components/ContentfulComponents/ComponentHighlightedText/type";
import { IProductLabelCflData } from "@components/ContentfulComponents/ComponentProductLabel/type";
import { IQaCflData } from "@components/ContentfulComponents/ComponentQA/type";
import { IRichTextCflData } from "@components/ContentfulComponents/ComponentRichText/type";
import { ITextAssetAndCtasCflData } from "@components/ContentfulComponents/ComponentTextAssetAndCtas/type";
import { ITextWithLinkCflData } from "@components/ContentfulComponents/ComponentTextWithLink/type";
import { IMultiContentPageCflData } from "@components/ContentfulComponents/ComposeMultiContent/type";
import { ITextOnlyPageCflData } from "@components/ContentfulComponents/ComposeTextOnly/type";

type TContent = TTranslatableContent | ISeparatorCflData;

// GraphQL
export const renderTextOptions = (text: string, additionalRenderTextOptions?: RenderText) => {
	if (!text) return null;
	const nonBreakingSymbolText = replaceAllBreakSpaces(text);
	if (!additionalRenderTextOptions) {
		return nonBreakingSymbolText;
	} else {
		return additionalRenderTextOptions(nonBreakingSymbolText);
	}
};

export const getTargetBlankLinkOptions = (
	additionalRenderNodeOptions: RenderNode,
	additionalRenderMarkOptions?: RenderMark,
	additionalRenderTextOptions?: RenderText
): Options => ({
	renderNode: {
		[INLINES.HYPERLINK]: (node, children) => (
			<span>
				{node?.data?.uri ? (
					<a href={node.data.uri} target="_blank" rel="noreferrer">
						{children}
					</a>
				) : (
					children
				)}
			</span>
		),
		...additionalRenderNodeOptions
	},
	renderMark: {
		...additionalRenderMarkOptions
	},
	renderText: (text: string) => renderTextOptions(text, additionalRenderTextOptions)
});

export const convertRelOptions = (relOptions?: string[]) => {
	return relOptions?.join(" ");
};

export const convertTargetOptions = (targetOptions?: string) => {
	return targetOptions || "_self";
};

// Type guards

/** COMPONENTS */

//TODO: what if assset is undefined
export const isAsset = (asset: TContent): asset is IAssetCflData =>
	asset.__typename === EComponentTypenames.Asset;

export const isAssetWithText = (assetWithText: TContent): assetWithText is IAssetWithTextCflData =>
	assetWithText.__typename === EComponentTypenames.AssetWithText;

export const isBanner = (banner: TContent): banner is IBannerCflData =>
	banner.__typename === EComponentTypenames.Banner;

export const isHighlightedText = (
	highlightedText: TContent
): highlightedText is IHighlightedTextCflData =>
	highlightedText.__typename === EComponentTypenames.HighlightedText;

export const isJson = (json: TContent): json is IJsonCflData =>
	json.__typename === EComponentTypenames.Json;

export const isLink = (link: TContent): link is ILinkCflData =>
	link.__typename === EComponentTypenames.Link;

export const isMappingId = (mappingId: TContent): mappingId is ILinkCflData =>
	mappingId.__typename === EComponentTypenames.MappingId;

export const isMedia = (media: TContent): media is IMediaCflData =>
	media.__typename === EComponentTypenames.Media;

export const isProducltLabel = (label: TContent): label is IProductLabelCflData =>
	label.__typename === EComponentTypenames.ProductLabel;

export const isQa = (content: TContent): content is IQaCflData =>
	content.__typename === EComponentTypenames.QA;

export const isRichText = (richText: TContent): richText is IRichTextCflData =>
	richText.__typename === EComponentTypenames.RichText;

export const isSeparator = (content: TContent): content is ISeparatorCflData =>
	content.__typename === EComponentTypenames.Separator;

export const isTextAssetCtas = (content: TContent): content is ITextAssetAndCtasCflData =>
	content.__typename === EComponentTypenames.TextAssetCtas;

export const isTextWithLink = (content: TContent): content is ITextWithLinkCflData =>
	content.__typename === EComponentTypenames.TextWithLink;

export const isTranslationKey = (translation: TContent): translation is ITranslationKeyCflData =>
	translation.__typename === EComponentTypenames.TranslationKey;

/** END - COMPONENTS */

/** BLOCKS */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isContentBlock = (contentBlock: TContent): contentBlock is IContentBlockCflData<any> =>
	contentBlock.__typename === EBlockTypenames.ContentBlock;

export const isContentPagesContentBlock = (
	contentBlock: TContent
): contentBlock is IContentPagesContentBlockCflData =>
	contentBlock.__typename === EBlockTypenames.PagesContentBlock;

export const isTwoColumn = (content: TContent): content is ITwoColumnsCflData =>
	content.__typename === EBlockTypenames.TwoColumns;

/** END - BLOCKS */

/** PAGES */
export const isMultiContent = (
	pageContent: IMultiContentPageCflData | ITextOnlyPageCflData
): pageContent is IMultiContentPageCflData =>
	pageContent.__typename === EPageTypenames.MultiContent;

export const isTextOnly = (
	pageContent: IMultiContentPageCflData | ITextOnlyPageCflData
): pageContent is ITextOnlyPageCflData => pageContent.__typename === EPageTypenames.Text;

export const isContentfulPageType = (pageContent: string): pageContent is EContentfulPageType =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.values(EContentfulPageType).includes(pageContent as any);

/** END - PAGES */

export const convertDefaultLocale = (locale: string) => {
	return locale === defaultSiteLocale ? defaultLocale : locale;
};
