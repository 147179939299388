import { setErrorToken } from "@shared/auth/cookie.utils";
import { ERefreshError } from "@shared/auth/token.types";
import { signIn, useSession } from "next-auth/react";
import { useEffect } from "react";

const SignInError = () => {
	const { data: session } = useSession();

	useEffect(() => {
		if (
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(session as any)?.error === ERefreshError.RefreshAccessTokenError
		) {
			setErrorToken({});
			signIn("FedId");
		}
	}, [session]);

	return null;
};

export default SignInError;
