import {
	ESessionStorageName,
	getSessionStorageItem,
	isSessionStorageItemExists,
	removeSessionStorageItem
} from "./sessionStorage.utils";

export const shouldRemoveHitLastViewed = () => {
	if (!isSessionStorageItemExists(ESessionStorageName.HitLastViewed)) {
		return;
	}

	const { productCardReferer } = getSessionStorageItem(ESessionStorageName.HitLastViewed, "object");
	const currentURL = window.location.href;

	if (
		productCardReferer &&
		productCardReferer !== currentURL &&
		document.referrer !== productCardReferer
	) {
		removeSessionStorageItem(ESessionStorageName.HitLastViewed);
	}
};
