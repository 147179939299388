import { IGenericAction } from "@redux/types";

export enum EFilter {
	AddDisplayedFilterAnchor = "AddDisplayedFilterAnchor",
	RemoveDisplayedFilterAnchor = "RemoveDisplayedFilterAnchor"
}

export type TFilterActions = IGenericAction<EFilter, string>;

export const addDisplayedFilterAnchor = (filter: string) => {
	return {
		type: EFilter.AddDisplayedFilterAnchor,
		payload: filter
	};
};

export const removeDisplayedFilterAnchor = (filter: string) => {
	return {
		type: EFilter.RemoveDisplayedFilterAnchor,
		payload: filter
	};
};
