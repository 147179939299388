import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { screens } from "@config/devices";
import { EDevice } from "@shared/types/misc.types";

const mobile = screens[EDevice.Mobile];
const tablet = screens[EDevice.Tablet];
const desktop = screens[EDevice.Desktop];
const bigScreen = screens[EDevice.BigScreen];

export interface IDevicesResponsive {
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
	isBigScreen: boolean;
}

export const useResponsive = (): IDevicesResponsive => {
	const isMobile = useMediaQuery({ maxWidth: mobile.max });
	const isTablet = useMediaQuery({
		minWidth: tablet.min,
		maxWidth: `${Number(desktop.min.substring(0, desktop.min.indexOf("px"))) - 1}px` // desktop min size - 1
	});
	const isDesktop = useMediaQuery({
		minWidth: desktop.min,
		maxWidth: `${Number(bigScreen.min.substring(0, bigScreen.min.indexOf("px"))) - 1}px` // bigScreen min size - 1
	});
	const isBigScreen = useMediaQuery({ minWidth: bigScreen.min });

	return { isMobile, isTablet, isDesktop, isBigScreen };
};

export const useResponsiveOnLoad = () => {
	const [isLoad, setLoad] = useState(false);
	const devices = useResponsive();

	useEffect(() => {
		if (typeof window !== "undefined") {
			setLoad(true);
		}
	}, [isLoad]);

	return { isLoad, devices };
};
